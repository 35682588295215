/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
// import dashboard from './dashboard'
// import appsAndPages from './apps-and-pages'
// import others from './others'
// import chartsAndMaps from './charts-and-maps'
// import uiElements from './ui-elements'
// import formAndTable from './forms-and-table'
import annualStatement from './annualstatement'
import overview from './overview'

// Array of sections
// export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others, ...annualStatement, ...familyManagement, ...overview]
export default [ ...overview, ...annualStatement,   {
  title: 'Relaties',
  icon: 'UsersIcon',
  route: 'relations',
}, {
  title: 'Account',
  icon: 'ToolIcon',
  children: [
    {
      title: 'Profiel',
      icon: 'UserIcon',
      route: 'profile',
    },
    {
      title: 'Abonnement',
      icon: 'FileTextIcon',
      route: 'administration',
    }]
}, {
  title: 'Uitloggen',
  icon: 'LogOutIcon',
  route: 'logout'
} ]
